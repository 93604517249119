'use strict';

Gri.module({
  name: 'social-alpha',
  ieVersion: null,
  $el: $('.social-alpha'),
  container: '.social-alpha',
  fn: function () {
    
  }
});
